import React from "react";
import {
  GalleryGrid,
  GalleryRow,
  GalleryColumn,
} from "../../components/gallery/GalleryGrid";
import GalleryItemNew from "../../components/gallery/GalleryItemNew";

const StudiesGallery = () => {
  return (
    <GalleryGrid>
      <GalleryRow>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="The Finish Line"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/5ff29d81-c3f0-d4bd-02ad-25b65f35f226.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Shiver"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/9a0ece50-805e-b33b-3646-46a4eb9d3cd2.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Without a Trace"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/77976eec-dd1e-99dd-d8f9-647081506a40.jpg"
          />
        </GalleryColumn>
      </GalleryRow>

      <GalleryRow>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            width={400}
            title="Leftovers"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/648b13f6-9e9d-a9dc-6fea-d11fabe9d8da.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            width={400}
            title="Forfeit"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/07d0edcf-e077-6853-13e5-588ba4f6363a.jpg"
          />
        </GalleryColumn>
      </GalleryRow>

      <GalleryRow>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            width={300}
            bottom={80}
            title="Just a Moment"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/a4fdcc45-61e3-41d8-415d-b6f43c23ed1f.jpg"
          />
          <GalleryItemNew
            title="Ruminant"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/2e2afad2-7d9c-cd52-2186-222faaf43003.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40} justify="center">
          <GalleryItemNew
            title="Portrait on Cardstock"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/dc0ea9c7-19ad-35e7-d1a4-741b1322185f.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            width={300}
            bottom={80}
            title="Gasp"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/818dc8f4-c20b-fcce-9757-56dd8fdecb5d.jpg"
          />
          <GalleryItemNew
            title="Forgotten"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/e1ebbd4c-0616-6ab8-53a5-698efdd49b49.jpg"
          />
        </GalleryColumn>
      </GalleryRow>

      <GalleryRow>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="In Transit"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/5be03e1d-848e-db00-8442-55e9bbe6c3e4.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Yellow"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/7d527915-b9c0-a13f-3de5-0c10c022f567.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Solo"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/26cda646-2066-b74c-3fbf-06113a52e478.jpg"
          />
        </GalleryColumn>
      </GalleryRow>

      <GalleryRow>
        <GalleryColumn cols={5} padding={40} justify="center">
          <GalleryItemNew
            title="Mirror Portrait"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/d86fb35a-e796-dff6-455f-73de231b5bb3.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={8} padding={40} justify="center">
          <GalleryItemNew
            title="Study of Louis Finson - Allegory of Four Elements"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/5e43adcf-3a51-3b16-4f51-d743a29007c1.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={5} padding={40} justify="center">
          <GalleryItemNew
            title="Self-Portrait Age 21"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/9f23c1d3-8d4c-b057-620d-d106258b0b1d.jpg"
          />
        </GalleryColumn>
      </GalleryRow>

      <GalleryRow>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Cleopatra"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/85c338eb-76e4-88ce-5398-911ca4337269.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Sabor (framed)"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/f0add714-efca-81ef-13a6-e9c195fcc488.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Erica"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/4dc11947-8e79-8f60-024d-9b8c818a7c2e.jpg"
          />
        </GalleryColumn>
      </GalleryRow>

      <GalleryRow>
        <GalleryColumn cols={18 / 5}>
          <GalleryItemNew
            bottom={80}
            width={180}
            title="Delicacy"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/379b0a29-6767-9321-c75f-caf8079d94ee.jpg"
          />
          <GalleryItemNew
            width={150}
            title="Erode"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/dbabbd8b-2fb4-109c-65da-da57f3c17c24.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={18 / 5}>
          <GalleryItemNew
            bottom={80}
            width={180}
            title="Odyssey"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/051939d0-9d68-f507-d82a-f74cc9159ab2.jpg"
          />
          <GalleryItemNew
            width={150}
            title="En Media Res"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/36c64275-642b-1525-7841-b54037eee03b.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={18 / 5}>
          <GalleryItemNew
            bottom={80}
            width={180}
            title="Head Study"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/cebeb3c6-4334-d698-deed-c15c0e5858a4.jpg"
          />
          <GalleryItemNew
            width={150}
            title="Cancun Performer"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/99d97887-5a5f-e812-4d35-b9708ceec5a7.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={18 / 5}>
          <GalleryItemNew
            bottom={80}
            width={180}
            title="Puja"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/edad6118-245d-d94f-de75-8cac48415a8e.jpg"
          />
          <GalleryItemNew
            width={150}
            title="Self-Portrait Limited Palette"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/145e549d-4d34-b31b-ebd0-e92cb539e7f2.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={18 / 5}>
          <GalleryItemNew
            bottom={80}
            width={180}
            title="Essentials"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/72640315-37d0-0be3-2281-1834ef1202f2.jpg"
          />
          <GalleryItemNew
            width={150}
            title="Raphael"
            src="https://az-art-v2.s3.amazonaws.com/STUDIES/6c96537a-424e-66ed-792a-61e2cfab46f3.jpg"
          />
        </GalleryColumn>
      </GalleryRow>

      <GalleryRow>
        <GalleryColumn cols={18 / 4} padding={40}>
          <GalleryItemNew title="Shards" src="https://az-art-v2.s3.amazonaws.com/STUDIES/7340444b-adb4-4d70-61de-a244509c9f9a.jpg" />
        </GalleryColumn>
        <GalleryColumn cols={18 / 4} padding={40}>
          <GalleryItemNew title="Planes" src="https://az-art-v2.s3.amazonaws.com/STUDIES/1c9cd76e-47d5-6d9c-5013-a393b149236e.jpg" />
        </GalleryColumn>
        <GalleryColumn cols={18 / 4} padding={40}>
          <GalleryItemNew title="Out of the Shadows" src="https://az-art-v2.s3.amazonaws.com/STUDIES/7c1bcf5c-0bcd-d178-d111-2ea5d4107867.jpg" />
        </GalleryColumn>
        <GalleryColumn cols={18 / 4} padding={40}>
          <GalleryItemNew title="Cool Palette Figure Study" src="https://az-art-v2.s3.amazonaws.com/STUDIES/7bb1607f-4694-6df5-2160-68a2c14e09ef.jpg" />
        </GalleryColumn>
      </GalleryRow>
    </GalleryGrid>
  );
};

export default StudiesGallery;
