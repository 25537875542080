import React from "react";
import Videos from "../components/about/Videos";
import styled from "styled-components";
import PageWrapper from "../components/common/PageWrapper";
import { themeToBgColor } from "../util/theme";
import Quote from "../components/about/Quote";

const AboutPageWrapper = styled(PageWrapper)`
  flex-direction: column;
  justify-content: flex-start;
  width: 70%;
  margin: 0 auto;
  padding-top: 0;
  height: auto;
  // background-color: ${(props) => themeToBgColor(props.theme)};
`;

const Spacer = styled.div`
  min-height: 500px;
  width: 100%;
  background-color: black;
`;

const AboutPage = () => {
  return (
    <>
      <AboutPageWrapper>
        <Videos />
      </AboutPageWrapper>
      <Quote />
      {/* <AboutPageWrapper>
        <Awards />
      </AboutPageWrapper> */}
    </>
  );
};

export default AboutPage;
