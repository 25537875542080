import React from "react";
import { ThemeProvider } from "styled-components";
import CSS from "csstype";
import { BG_COLOR_WHITE, BG_COLOR_BLACK } from "../constants";

const ThemeConfigs: { [k: string]: CSS.Properties } = {
  light: {
    backgroundColor: BG_COLOR_WHITE,
    color: BG_COLOR_BLACK,
  },
  dark: {
    backgroundColor: BG_COLOR_BLACK,
    color: BG_COLOR_WHITE,
  },
};

const DEFAULT_THEME = ThemeConfigs.light;

type ThemeContextProps = {
  setTheme: (s: string) => void;
  getTheme: () => any;
  getThemeStyles: () => CSS.Properties;
};

const ThemeContext = React.createContext<ThemeContextProps>(
  {} as ThemeContextProps
);

export const useTheme = () => React.useContext(ThemeContext);

export const MyThemeProvider: React.FC = ({ children }) => {
  const [themeState, setThemeState] = React.useState({
    mode: "light",
  });

  const setTheme = (mode: string) => {
    setThemeState({ mode });
  };

  const getTheme = () => themeState;

  const getThemeStyles = () => ThemeConfigs[themeState.mode] || DEFAULT_THEME;

  return (
    <ThemeContext.Provider value={{ setTheme, getTheme, getThemeStyles }}>
      <ThemeProvider
        theme={{
          mode: themeState.mode,
        }}
      >
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default MyThemeProvider;
