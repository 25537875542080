import React, { useEffect, useState } from "react";
import styled from "styled-components";
import noteToSelfImg from "../../static/note-to-self-resized.jpg";
import { BG_COLOR_BLACK } from "../../constants";
import StyledLink from "../common/StyledLink";
import debounce from "../../util/debounce";

const FeaturedWorksWrapper = styled.div`
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: ${BG_COLOR_BLACK};
`;

const FeaturedWorkItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 160px;
  margin-bottom: 160px;
`;

const FeaturedWorkCaption = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  // width: 60%;
  color: white;
  text-align: left;
  z-index: 10;
`;

type FeaturedWorkImageProps = {
  blur: boolean;
};

const FeaturedWorkImage = styled.img<FeaturedWorkImageProps>`
  max-width: 500px;
  width: 100%;
  transition: filter 0.2s ease-in-out;
  filter: blur(${({ blur }) => (blur ? 5 : 0)}px);
  cursor: pointer;
`;

type FeaturedWorkTitleCharacterProps = {
  offset: number;
  letterSpacing?: number;
  direction: -1 | 1;
  blur: boolean;
};

const FeaturedWorkTitleCharacter = styled.h1<FeaturedWorkTitleCharacterProps>`
  margin: 0;
  padding: 0;
  font-size: 72px;
  display: inline;
  position: relative;
  transition: top 0.2s ease-in-out, bottom 0.2s ease-in-out,
    margin-left 0.4s ease-in-out, margin-right 0.4s ease-in-out;
  ${({ blur, letterSpacing }) =>
    blur &&
    `
    margin-left: ${letterSpacing || 20}px;
    margin-right: ${letterSpacing || 20}px;
  `}

  top: 0;
  bottom: 0;

  ${({ direction, offset, blur }) =>
    blur && (direction > 0 ? "top:" : "bottom:") + String(offset) + "px;"}
`;

type FeaturedWorkTitleProps = {
  content: string;
  blur: boolean;
  letterSpacing?: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const FeaturedWorkTitle = ({
  content,
  blur,
  letterSpacing,
  onMouseEnter,
  onMouseLeave,
}: FeaturedWorkTitleProps) => {
  const getRandomOffset = () =>
    blur ? Math.round(40 + Math.random() * 20) : 0;

  return (
    <div>
      {content.split("").map((char, i) => (
        <FeaturedWorkTitleCharacter
          key={char + String(i)}
          offset={getRandomOffset()}
          direction={i % 2 === 0 ? -1 : 1}
          letterSpacing={letterSpacing}
          blur={blur}
        >
          {char}
        </FeaturedWorkTitleCharacter>
      ))}
    </div>
  );
};

type FeaturedWorkSubtitleProps = {
  blur: boolean;
};

const FeaturedWorkSubtitle = styled.p<FeaturedWorkSubtitleProps>`
  text-indent: 0;
  letter-spacing: 0;
  font-size: 36px;
  margin-top: 60px;
  text-align: center;

  opacity: ${({ blur }) => (blur ? 0 : 1)};
  transition: opacity 0.2s ease;
`;

type FeaturedWorkItemProps = {
  title: string;
  caption: string;
  imgSrc: string;
  href: string;
  letterSpacing?: number;
};

const FeaturedWorkItem = ({
  title,
  caption,
  imgSrc,
  letterSpacing,
  href,
}: FeaturedWorkItemProps) => {
  const [captionBlur, setCaptionBlur] = useState(true);

  return (
    <StyledLink to={href}>
      <FeaturedWorkItemContainer
        onMouseOver={debounce(() => setCaptionBlur(false), 100)}
        onMouseOut={debounce(() => setCaptionBlur(true), 100)}
      >
        <FeaturedWorkImage src={imgSrc} alt={title} blur={!captionBlur} />
        <FeaturedWorkCaption>
          <FeaturedWorkTitle
            content={title}
            blur={captionBlur}
            letterSpacing={letterSpacing}
          />
          <FeaturedWorkSubtitle blur={captionBlur}>
            {caption}
          </FeaturedWorkSubtitle>
        </FeaturedWorkCaption>
      </FeaturedWorkItemContainer>
    </StyledLink>
  );
};

const FeaturedWorks = () => {
  return (
    <FeaturedWorksWrapper>
      <FeaturedWorkItem
        imgSrc="https://az-art-v2.s3.amazonaws.com/SOLITUDE/aa630e55-b5cd-1c06-5d99-95547d38dc4a.jpg"
        title="Folklore"
        caption="24 x 18, Oil"
        href="/gallery/solitude"
      />
      <FeaturedWorkItem
        imgSrc="https://az-art-v2.s3.amazonaws.com/SOLITUDE/a94ac597-aaaf-834d-039c-dd9e195a1ca0.jpg"
        title="One Step Outside"
        caption="10 x 8, Oil"
        letterSpacing={10}
        href="/gallery/solitude"
      />
      <FeaturedWorkItem
        imgSrc={noteToSelfImg}
        title="Note To Self"
        caption="20 x 16, Oil"
        href="/gallery/solitude"
      />
    </FeaturedWorksWrapper>
  );
};

export default FeaturedWorks;
