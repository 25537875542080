const BG_COLOR_BLACK: string = "#0c0805";
const BG_COLOR_WHITE: string = "#f1f0ef";

// Map of category names to URL of featured artwork
const GALLERY_CATEGORY_MAP: { [k: string]: string } = {
  "in-vitro":
    "https://az-art-v2.s3.amazonaws.com/IN+VITRO/c181ba64-fb04-704c-78b1-c5c3cacba330.jpg",
  "lilies":
    "https://az-art-v2.s3.amazonaws.com/LILIES/79733e7d-703e-3f57-9758-c447e0385219.jpg",
  "solitude":
    "https://az-art-v2.s3.amazonaws.com/SOLITUDE/cbd022c7-b76c-2b94-404c-9923ea36d733.jpg",
  "western":
    "https://az-art-v2.s3.amazonaws.com/WESTERN/45c86300-f48d-981e-2e30-e0fd2985a239.jpg",
  "drawings":
    "https://az-art-v2.s3.amazonaws.com/DRAWINGS/a557f02f-fefa-a26b-6756-553eb4e76541.jpg",
  "studies":
    "https://az-art-v2.s3.amazonaws.com/STUDIES/5ff29d81-c3f0-d4bd-02ad-25b65f35f226.jpg",
  "early-work":
    "https://az-art-v2.s3.amazonaws.com/EARLY+WORK/ff8487c6-b793-907a-a384-0826d2a3925c.jpg",
};

const GALLERY_CATEGORIES: string[] = [
  "Solitude",
  "In Vitro",
  "Western",
  "Lilies",
  "Drawings",
  "Studies",
  "Early Work",
];

enum ScreenSize {
  SM = 811,
  MD = 1199,
  LG = 1919,
}

export {
  BG_COLOR_BLACK,
  BG_COLOR_WHITE,
  GALLERY_CATEGORIES,
  GALLERY_CATEGORY_MAP,
  ScreenSize,
};
