import React, { useEffect } from "react";
import styled from "styled-components";
import { useTheme } from "../../theme/ThemeContext";
import { useInView } from "react-intersection-observer";
import { themeToMainColor, themeToBgColor } from "../../util/theme";
import { ScreenSize } from "../../constants";
import { mediaMinWidth } from "../../util/media";
import alternativeSignature from "../../static/alternative-signature.png";

const AwardsPublicationsContainer = styled.div`
  padding-top: 100px;
  margin-left: 160px;
  margin-right: 160px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10%;
  color: ${(props) => themeToMainColor(props.theme)};

  img {
    max-width: 500px;
  }

  ${mediaMinWidth(ScreenSize.LG)} {
    margin: 0 auto;
    width: 60%;
  }
`;

const AwardsImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-item: center;
  justify-content: center;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 150px;
  }
`;

const Signature = styled.img`
  z-index: 10;
  position: absolute;
`;

const AwardsPublicationsList = styled.ul`
  list-style-type: "-";
  margin: 0 0 50px 0;
  padding: 0;
  color: ${(props) => themeToMainColor(props.theme)};

  li {
    font-size: 1.5em;
    margin: 1em;
  }

  li:before {
    content: "";
    margin: 0 5px;
  }
`;

const RepresentationContainer = styled.div`
  margin-left: 160px;
  margin-right: 160px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5%;
  margin-top: 50px;
  padding-bottom: 100px;

  h2 {
    font-family: freight-big-pro;
  }
`;

const RepresentationBox = styled.a`
  border: 1px solid ${(props) => themeToBgColor(props.theme)};
  text-decoration: none;
  color: ${(props) => themeToMainColor(props.theme)};
  transition: 0.2s ease opacity, 0.2s ease border;

  &:hover {
    border: 1px solid ${(props) => themeToMainColor(props.theme)};
    opacity: 0.9;
  }
  h2 {
    font-family: freight-big-pro;
  }
`;

const RepresentationTitle = styled.h1`
  margin-top: 100px;
  color: ${(props) => themeToMainColor(props.theme)};
`;

const Awards: React.FC = () => {
  const themeContext = useTheme();
  const [ref, inView, _] = useInView({
    threshold: 0.6,
  });

  useEffect(() => {
    themeContext.setTheme(inView ? "dark" : "light");
  }, [inView]);

  useEffect(() => {
    const { mode } = themeContext.getTheme();
    return () => themeContext.setTheme(mode);
  }, []);

  return (
    <div ref={ref}>
      <AwardsPublicationsContainer>
        <AwardsImageWrapper>
          <img
            src="https://d33wubrfki0l68.cloudfront.net/cbc28e8a4bb64f5f567277e2f3dbf55c86c1662f/285da/imgs/about/about_awards.jpg"
            alt="anton sitting on stool"
          />
          <video autoPlay loop>
            <source
              src="https://az-art-v2.s3.amazonaws.com/static/out.webm"
              type="video/webm"
            />
          </video>
          {/* <Signature src={alternativeSignature} /> */}
        </AwardsImageWrapper>
        <div style={{ textAlign: "left" }}>
          <h1>Awards</h1>
          <AwardsPublicationsList>
            <li>
              Texas Young Master - Awarded by the Texas Commission on the Arts
            </li>
            <li>
              Perfect Score Portfolio - College Board 2D Design Portfolio Exam
            </li>
            <li>
              Junior Scholar Member - The Salmagundi Club in New York City
            </li>
          </AwardsPublicationsList>
          <h1>Publications</h1>
          <AwardsPublicationsList>
            <li>Visual Language Magazine March 2015 - Featured Artist</li>
            <li>The Adroit Journal Issue Thirteen - Featured Artist</li>
            <li>Columbia Daily Spectator - “The Eye” Illustrator</li>
          </AwardsPublicationsList>
        </div>
      </AwardsPublicationsContainer>
      <RepresentationTitle>Representation</RepresentationTitle>
      <RepresentationContainer>
        <RepresentationBox
          href="https://www.jackmeiergallery.com/antonzhou"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2>The Jack Meier Gallery</h2>
          <p>2310 Bissonet St.</p>
          <p>Houston, TX 77005</p>
        </RepresentationBox>
        <RepresentationBox
          href="http://www.salmagundi.org/index.cfm"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2>The Salmagundi Club</h2>
          <p>47 5th Ave.</p>
          <p>New York, NY 10003</p>
        </RepresentationBox>
      </RepresentationContainer>
    </div>
  );
};

export default Awards;
