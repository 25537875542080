import React from "react";
import {
  GalleryGrid,
  GalleryRow,
  GalleryColumn,
} from "../../components/gallery/GalleryGrid";
import GalleryItemNew from "../../components/gallery/GalleryItemNew";

const DrawingsGallery = () => {
  return (
    <GalleryGrid>
      <GalleryRow>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Bliss - Charcoal - 17 X 14"
            src="https://az-art-v2.s3.amazonaws.com/DRAWINGS/9cd86ea9-2a96-b8ef-ba41-d70fb26b3c2c.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Steam - Charcoal - 12 X 9"
            src="https://az-art-v2.s3.amazonaws.com/DRAWINGS/a557f02f-fefa-a26b-6756-553eb4e76541.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Crescent - Charcoal - 17 X 14"
            src="https://az-art-v2.s3.amazonaws.com/DRAWINGS/5a4f3dde-a14a-6974-4dda-52bfb5def5dd.jpg"
          />
        </GalleryColumn>
      </GalleryRow>

      <GalleryRow>
        <GalleryColumn cols={5} padding={40} justify="center">
          <GalleryItemNew
            title="Visions of Grandeur (framed)"
            src="https://az-art-v2.s3.amazonaws.com/DRAWINGS/5cbf865d-aa4a-5210-4798-d9cab3719f4a.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={10} padding={40} justify="center">
          <GalleryItemNew
            title="Visions of Grandeur - Graphite - 24 X 18"
            src="https://az-art-v2.s3.amazonaws.com/DRAWINGS/3be0d070-ebee-3584-ac89-cda65600771f.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={5} padding={40} justify="center">
          <GalleryItemNew
            title="Joker - Charcoal - 17 X 14"
            src="https://az-art-v2.s3.amazonaws.com/DRAWINGS/abcc187d-b566-3f79-f55d-4c1f45eb111d.jpg"
          />
        </GalleryColumn>
      </GalleryRow>

      <GalleryRow>
        <GalleryColumn cols={9} padding={40}>
          <GalleryItemNew
            title="Invigoration - Charcoal - 18 X 24"
            src="https://az-art-v2.s3.amazonaws.com/DRAWINGS/ff380431-0458-23ba-2cef-1d5d49c42909.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={9} padding={40}>
          <GalleryItemNew
            title="A New Chapter - Ink, Watercolor - 18 X 24"
            src="https://az-art-v2.s3.amazonaws.com/DRAWINGS/bf5db6fa-be2f-845b-a050-a1eef4fbf475.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
    </GalleryGrid>
  );
};

export default DrawingsGallery;
