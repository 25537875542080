import { BG_COLOR_BLACK, BG_COLOR_WHITE } from "../constants";

export type ThemeProps = {
  mode: "light" | "dark";
};

const themeToBgColor = ({ mode: theme }: ThemeProps) => {
  if (theme === "light") return BG_COLOR_WHITE;
  return BG_COLOR_BLACK;
};

const themeToMainColor = ({ mode: theme }: ThemeProps) => {
  if (theme === "light") return BG_COLOR_BLACK;
  return BG_COLOR_WHITE;
};

export { themeToBgColor, themeToMainColor };
