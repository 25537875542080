import React from "react";
import styled from "styled-components";
import { Link, LinkProps } from "react-router-dom";

const StyledLink = styled(Link)<LinkProps>`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

export default (props: LinkProps) => <StyledLink {...props} />;
