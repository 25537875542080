import React from "react";

export default function NewTabLink(props: React.HTMLProps<HTMLAnchorElement>) {
  return (
    <a
      {...props}
      target="_blank"
      rel={`${props.rel} noopener noreferrer`}
      style={{ ...props.style, textDecoration: "none" }}
    >
      {props.children}
    </a>
  );
}
