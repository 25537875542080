import styled from "styled-components";

const GalleryGrid = styled.div`
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 90px;
  padding-bottom: 90px;
  margin: 0 auto;
  max-width: 1280px;
  height: auto;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const GalleryRow = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-around;
`;

type GalleryColumnProps = {
  cols?: number;
  padding?: number;
  align?: string;
  justify?: string;
};

const MAX_COLS = 18;

const GalleryColumn = styled.div<GalleryColumnProps>`
  width: ${({ cols }) =>
    cols ? String((cols / MAX_COLS) * 100) + "%" : "auto"};
  height: auto;
  padding-left: ${({ padding }) => padding || 20}px;
  padding-right: ${({ padding }) => padding || 20}px;
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align || "center"};
  justify-content: ${({ justify }) => justify || "flex-start"};
`;

export { GalleryGrid, GalleryRow, GalleryColumn };
