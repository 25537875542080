import React, { useState, useRef } from "react";
import styled from "styled-components";
import { BG_COLOR_WHITE } from "../../constants";
import inspirationStroke from "../../static/inspiration-stroke.png";
import originStroke from "../../static/origin-stroke.png";

const VideosWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
`;

const AboutPaintStroke = styled.img``;

interface AboutVideoWrapperProps {
  hovered: boolean;
}

const AboutVideoWrapper = styled.div<AboutVideoWrapperProps>`
  margin-left: 25px;
  margin-right: 25px;
  width: ${(props) => (props.hovered ? 50 : 40)}%;
  height: 70vh;
  min-height: 250px;
  position: relative;
  display: flex;
  align-item: center;
  justify-content: center;

  transition: width 0.4s ease, height 0.4s ease;
`;

const AboutVideoTitle = styled.h1<AboutVideoWrapperProps>`
  z-index: 10;
  margin: auto 0;
  color: ${BG_COLOR_WHITE};
  opacity: ${(props) => (props.hovered ? 0 : 1)};
  filter: blur(${(props) => (props.hovered ? 3 : 0)}px);
  visibility: ${(props) => (props.hovered ? "hidden" : "visible")};
  transition: 0.2s ease opacity, 0.2s ease filter, 0s linear visilility 0.2s;
  position: absolute;
  top: 50%;
  left: 50%;
`;

const AboutVideo = styled.video<AboutVideoWrapperProps>`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: auto;
  background-size: cover;
  overflow: hidden;
  transition: 0.2s ease filter;
  filter: blur(${(props) => (props.hovered ? 0 : 10)}px);

  &:focus {
    outline: none;
  }
`;

const AboutVideos = () => {
  const [videoHoverLeft, setVideoHoverLeft] = useState<boolean>(false);
  const [videoHoverRight, setVideoHoverRight] = useState<boolean>(false);
  const leftVideoRef = useRef<HTMLVideoElement>(null);
  const rightVideoRef = useRef<HTMLVideoElement>(null);

  const handleHover = (right: boolean, state: boolean) => {
    if (right) {
      setVideoHoverRight(state);
    } else {
      setVideoHoverLeft(state);
    }

    if (!state) {
      if (right) {
        rightVideoRef.current && rightVideoRef.current.pause();
      } else {
        leftVideoRef.current && leftVideoRef.current.pause();
      }
    }
  };

  return (
    <VideosWrapper>
      <AboutVideoWrapper
        hovered={videoHoverLeft}
        onMouseOver={() => handleHover(false, true)}
        onMouseLeave={() => handleHover(false, false)}
      >
        <AboutPaintStroke src={originStroke} />
        <AboutVideoTitle hovered={videoHoverLeft}>THE ORIGIN</AboutVideoTitle>
        <AboutVideo
          ref={leftVideoRef}
          controls={videoHoverLeft}
          controlsList="nodownload"
          hovered={videoHoverLeft}
        >
          <source src="https://az-art-uploads.s3.amazonaws.com/static/about_part_1.mp4" />
        </AboutVideo>
      </AboutVideoWrapper>
      <AboutVideoWrapper
        hovered={videoHoverRight}
        onMouseOver={() => handleHover(true, true)}
        onMouseLeave={() => handleHover(true, false)}
      >
        <AboutPaintStroke src={inspirationStroke} />
        <AboutVideoTitle hovered={videoHoverRight}>
          THE INSPIRATION
        </AboutVideoTitle>
        <AboutVideo
          ref={rightVideoRef}
          controls={videoHoverRight}
          controlsList="nodownload"
          hovered={videoHoverRight}
          preload="metadata"
        >
          <source src="https://az-art-uploads.s3.amazonaws.com/static/about_part_2.mp4#t=1" />
        </AboutVideo>
      </AboutVideoWrapper>
    </VideosWrapper>
  );
};

export default AboutVideos;
