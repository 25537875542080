import React from "react";
import { Switch, Route } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import IndexPage from "./pages/IndexPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import { useTheme } from "./theme/ThemeContext";
import GalleryRouter from "./pages/GalleryRouter";

const AppWrapper = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
`;

function App() {
  const themeContext = useTheme();
  const { mode } = themeContext.getTheme();

  return (
    <ThemeProvider theme={{ mode }}>
      <AppWrapper>
        <Nav hide={false} />
        <Switch>
          <Route path="/about" exact>
            <AboutPage />
          </Route>
          <Route path="/contact" exact>
            <ContactPage />
          </Route>
          <Route path="/gallery">
            <GalleryRouter />
          </Route>
          <Route path="/" exact>
            <IndexPage />
          </Route>
        </Switch>
        <Footer />
      </AppWrapper>
    </ThemeProvider>
  );
}

export default App;
