import React from "react";
import {
  GalleryGrid,
  GalleryRow,
  GalleryColumn,
} from "../../components/gallery/GalleryGrid";
import GalleryItemNew from "../../components/gallery/GalleryItemNew";

const SolitudeGallery = () => {
  return (
    <GalleryGrid>
      <GalleryRow>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            width={300}
            title="Folklore (framed)"
            src="https://az-art-v2.s3.amazonaws.com/SOLITUDE/cbd022c7-b76c-2b94-404c-9923ea36d733.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            height={400}
            title="Quiet Hours - Oil - 20 X 16"
            src="https://az-art-v2.s3.amazonaws.com/SOLITUDE/0780bc23-3dad-5d5f-571f-0f8a753d2f0d.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            width={275}
            title="An Old Melody (framed)"
            src="https://az-art-v2.s3.amazonaws.com/SOLITUDE/9e6d530c-5d0b-c0a1-ee2b-c75dfb41a73e.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
      <GalleryRow>
        <GalleryColumn cols={12}>
          <GalleryItemNew
            title="Aren't You Tired Trying to Fill that Void - Oil - 18 X 24"
            src="https://az-art-v2.s3.amazonaws.com/SOLITUDE/cab10415-6f5c-eb27-238c-13e51d32f4ca.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
      <GalleryRow>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            width={250}
            bottom={80}
            title="Clear the Mind - Oil - 24 X 18"
            src="https://az-art-v2.s3.amazonaws.com/SOLITUDE/233e2756-648e-beca-0bcc-ab73430ee32e.jpg"
          />
          <GalleryItemNew
            width={250}
            title="Out on the Slopes - Oil - 14 X 11"
            src="https://az-art-v2.s3.amazonaws.com/SOLITUDE/e2cc8976-11bf-c3aa-7962-86597d30e799.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            width={400}
            bottom={80}
            title="Let it Sink In - Oil - 24 X 36"
            src="https://az-art-v2.s3.amazonaws.com/SOLITUDE/3a6f0176-c030-99f8-fac6-244849aa0b0a.jpg"
          />
          <GalleryItemNew
            width={400}
            title="Note to Self - Oil - 20 X 16"
            src="https://az-art-v2.s3.amazonaws.com/SOLITUDE/7b6506b6-214f-0536-aeab-69228ddd629f.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            width={250}
            bottom={80}
            title="And Now I Just Sit in Silence - Oil - 24 X 18"
            src="https://az-art-v2.s3.amazonaws.com/SOLITUDE/d61b3791-ba04-6114-6d1e-b7e50accc38c.jpg"
          />
          <GalleryItemNew
            width={250}
            title="Blinded by My Own Thoughts - Oil - 16 X 12"
            src="https://az-art-v2.s3.amazonaws.com/SOLITUDE/af6c9138-7702-0ab7-aee3-da38f5e9d913.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
      <GalleryRow>
        <GalleryColumn cols={9} padding={40}>
          <GalleryItemNew
            // width={300}
            title="The Long Way Home - Oil - 16 X 12"
            src="https://az-art-v2.s3.amazonaws.com/SOLITUDE/b5356842-cb43-8dc4-d0d2-a7222a8a6637.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={9} padding={40}>
          <GalleryItemNew
            // width={300}
            title="One Step Outside and You're Caught in the Crossfire - Oil - 8 X 10"
            src="https://az-art-v2.s3.amazonaws.com/SOLITUDE/882d0d04-a4d8-aaa7-3c22-68203738b08e.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
    </GalleryGrid>
  );
};

export default SolitudeGallery;
