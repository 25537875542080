import React from "react";
import styled from "styled-components";
import { BG_COLOR_BLACK } from "../../constants";

const ButtonWrapper = styled.div`
  width: 150px;
  height: 60px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  transition: 0.3s ease all;
  font-family: freight-neo-pro;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${BG_COLOR_BLACK};
    background-color: white;
    cursor: pointer;
  }
`;

interface ButtonProps {
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ onClick, children }) => {
  const blankFn = () => {};
  return <ButtonWrapper onClick={onClick || blankFn}>{children}</ButtonWrapper>;
};

export default Button;
