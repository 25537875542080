import React from "react";
import styled from "styled-components";
import HeroImage from "../../static/about_headshot.jpg";
import ImageLeft from "../../static/PANA9019.jpg";
import ImageTopRight from "../../static/PANA9076.jpg";
import ImageBottomRight from "../../static/PANA9044.jpg";

const QuoteContainer = styled.div`
  position: relative;
  height: auto;
`;

const QuoteBgImage = styled.img`
  max-width: 250px;
  max-height: 500px;
`;

const Quote: React.FC = () => {
  return (
    <QuoteContainer>
      {/* <img src={HeroImage} alt="headshot of anton" height={700} />
      <QuoteBgImage src={ImageLeft} alt="brushes" style={{ top: "750px" }} /> */}
    </QuoteContainer>
  );
};

export default Quote;
