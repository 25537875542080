import React from "react";
import styled from "styled-components";

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  max-width: 600px;
`;

const ContactFormLabel = styled.label`
  font-size: 18px;
  margin-bottom: 8px;
`;

const ContactFormInput = styled.input`
  width: 100%;
  font-family: inherit;
  font-size: 18px;
  padding: 15px;
  border: 1px solid grey;
  margin-bottom: 18px;

  &:focus {
    outline: none;
    border-color: black;
  }
`;

const ContactFormTextarea = styled.textarea`
  width: 100%;
  font-family: inherit;
  font-size: 18px;
  resize: vertical;
  padding: 15px;
  height: 200px;
  border: 1px solid grey;
  margin-bottom: 18px;

  &:focus {
    outline: none;
    border-color: black;
  }
`;

const ContactFormSubmit = styled.button`
  background-color: black;
  color: white;
  border: none;
  height: 50px;
  width: 140px;
  cursor: pointer;
  margin-top: 18px;
`;

const ContactPage = () => {
  return (
    <ContactWrapper>
      <span>For inquiries and commission requests, please fill out the form below</span>
      <ContactForm name="contact" method="post">
        <input type="hidden" name="form-name" value="contact" />
        <ContactFormLabel>Name</ContactFormLabel>
        <ContactFormInput name="name" type="text" />

        <ContactFormLabel>Email</ContactFormLabel>
        <ContactFormInput name="email" type="text" />

        <ContactFormLabel>Subject</ContactFormLabel>
        <ContactFormInput name="subject" type="text" />

        <ContactFormLabel>Message</ContactFormLabel>
        <ContactFormTextarea name="message" />
        <ContactFormSubmit>Submit</ContactFormSubmit>
      </ContactForm>
    </ContactWrapper>
  );
};

export default ContactPage;
