import styled from "styled-components";

const PageWrapperStyle = styled.div`
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
  padding-bottom: 150px;
  overflow-x: hidden;

  transition: 0.2s ease background-color;
`;

export default PageWrapperStyle;
