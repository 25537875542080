import React from "react";
import styled from "styled-components";
import topDecal from "../../static/personal_statement_2.png";
import bottomDecal from "../../static/personal_statement_1.png";
import aggressiveStrokes from "../../static/aggressive-strokes.png";
import tightStrokes from "../../static/tight-strokes.png";
import { mediaMinWidth } from "../../util/media";
import { ScreenSize } from "../../constants";

const ArtistStatementWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 100px;

  ${mediaMinWidth(ScreenSize.LG)} {
    margin: 0 auto;
    width: 60%;
  }
`;

const ArtistStatementTitle = styled.h1`
  writing-mode: vertical-rl;
  text-align: right;
  transform: rotate(180deg);
  margin: 0;
  font-size: 36px;
`;

const Divider = styled.div`
  width: 10px;
  min-width: 10px;
  height: 400px;
  background-color: #bdbdbd;
  margin-left: 32px;
  margin-right: 32px;
`;

const Content = styled.p`
  font-size: 36px;
  width: auto;
  font-family: Montserrat;
  line-height: 60px;
  font-weight: 400;
  margin: 0;
  text-align: left;
`;

const Emphasis = styled.span`
  font-size: 64px;
`;

const PersonalStatementWrapper = styled.div`
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  height: auto;
  ${mediaMinWidth(ScreenSize.LG)} {
    margin: 0 auto;
    width: 65%;
  }
`;

const Subheadline = styled.h1`
  color: #25374d;
  font-size: 64px;
  line-height: 60px;
  margin: 0;
  text-align: left;
  margin-left: 80px;
`;

const PersonalStatementCard = styled.div`
  font-size: 36px;
  line-height: 60px;
  margin-top: -32px;
  font-family: Montserrat;
  text-align: left;
  margin-left: 160px;
  margin-right: 160px;
  padding: 16px 60px;
  background: #F2F2F2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const PersonalStatementImage = styled.img`
  max-width: 440px;
  position: absolute;
`;

const ArtistStatement: React.FC = () => {
  return (
    <>
      <ArtistStatementWrapper>
        <ArtistStatementTitle>ARTIST</ArtistStatementTitle>
        <ArtistStatementTitle>STATEMENT</ArtistStatementTitle>
        <Divider />
        <Content>
          <Emphasis>“Crafted</Emphasis> by classical methods and embellished
          with a vibrant palette, my artistic practice revolves around
          expressing the ephemeral nature of our surroundings. The transitory
          state of each happening allows for only brief but pure recollections,
          so adopting a candid lens is a way of genuinely feeling moments,
          examining their context and illuminating the beauty woven into this
          space...
        </Content>
      </ArtistStatementWrapper>
      <PersonalStatementWrapper>
        <Subheadline>A Painter of Light,</Subheadline>
        <PersonalStatementCard>
          <p>
            I find myself maneuvering between tight brush strokes and aggressive
            mark making to depict luminosity in a tranquil yet dynamic fashion.
            I’m particularly intrigued by light’s ability to contextualize
            intimate stories in a constantly changing world. My work seeks to
            chronicle the various emotions and experiences that construct these
            narratives.”
          </p>
        </PersonalStatementCard>
        <PersonalStatementImage src={topDecal} style={{ right: 0, top: 0 }} />
        <PersonalStatementImage src={bottomDecal} style={{ left: -50, bottom: -60 }} />
      </PersonalStatementWrapper>
    </>
  );
};

export default ArtistStatement;
