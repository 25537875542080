import React from "react";
import {
  GalleryGrid,
  GalleryRow,
  GalleryColumn,
} from "../../components/gallery/GalleryGrid";
import GalleryItemNew from "../../components/gallery/GalleryItemNew";

const InVitroGallery = () => {
  return (
    <GalleryGrid>
      <GalleryRow>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            width={300}
            title="Adrift - Oil - 16 X 12"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/466607bc-4467-933a-a750-3e45d114a1c4.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            height={400}
            title="Precipitate - Oil - 20 X 16"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/b6ffe65d-acf3-f5db-6b82-c0c1a1e357c6.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            width={275}
            title="Eye of the Storm - Oil - 24 X 18"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/170fd69e-8cb1-e511-3040-4e57ff9a2347.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
      <GalleryRow>
        <GalleryColumn cols={9} padding={80}>
          <GalleryItemNew
            title="Reverie - Oil - 30 X 24"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/c181ba64-fb04-704c-78b1-c5c3cacba330.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={9} padding={80}>
          <GalleryItemNew
            title="Eclipse - Oil - 48 X 36"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/c74c9e9a-312c-5c13-f6f8-74378d6b3938.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
      <GalleryRow>
        <GalleryColumn justify="center" cols={6}>
          <GalleryItemNew
            width={300}
            bottom={80}
            title="Echoes of Yesterday - Oil - 10 X 8"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/9300b668-dec0-f6f2-87f3-1cbe227a6b70.jpg"
          />
          <GalleryItemNew
            title="Lose Yourself - Oil - 24 X 18"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/4bfb3737-9f90-ee30-08d0-79d269379457.jpg"
          />
        </GalleryColumn>
        <GalleryColumn justify="center" cols={6}>
          <GalleryItemNew
            width={300}
            bottom={80}
            title="Flotsam - Oil - 20 X 24"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/420b615e-01f1-398c-2839-eb49164ee436.jpg"
          />
          <GalleryItemNew
            width={270}
            title="Tidal Wave - Oil - 10 X 10"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/d4ae0c3b-1c4c-f51d-5b37-2e09b27293c5.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            bottom={80}
            title="Tidal Wave - Oil - 10 X 10"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/d9cf84e4-02df-9c7a-2a17-958cb909d718.jpg"
          />
          <GalleryItemNew
            title="Your Refractions Keep Me Stargazing - Oil - 20 X 16"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/182ad174-65d0-c192-bb6b-7cd99d8fb7a9.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
      <GalleryRow>
        <GalleryColumn cols={9} align="flex-end" padding={40}>
          <GalleryItemNew
            width={300}
            bottom={90}
            title="Glimmer of Complexion - Oil - 36 X 24"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/837a03bc-4b66-95e3-8140-b97ba72988b3.jpg"
          />
          <GalleryItemNew
            width={300}
            title="Ethereal - Oil - 36 X 24"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/2256dd7b-b129-39cc-b4b9-95b6dd6093fd.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={9} align="flex-start" padding={40}>
          <GalleryItemNew
            width={300}
            bottom={90}
            title="Fleeting Serenade - Oil - 36 X 24"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/64fa5ba6-6a47-46b6-2675-afa70080e0c7.jpg"
          />
          <GalleryItemNew
            width={300}
            title="Channel of Thought - Oil - 36 X 24"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/09464155-d989-e80c-7f57-ad83d06df05d.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
      <GalleryRow>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            width={300}
            bottom={90}
            title="Breach - Oil - 20 X 16"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/a153f142-6d0f-8fa2-c33f-616d3f908952.jpg"
          />
          <GalleryItemNew
            width={300}
            title="Engulfed - Oil - 20 X 16"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/517ee7ba-05db-f356-92ed-0a764ab927ac.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} justify="center">
          <GalleryItemNew
            width={300}
            title="Resonate - Oil - 20 X 16"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/01b9f445-0b33-2511-d985-73c6fa7f6544.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6}>
          <GalleryItemNew
            width={300}
            bottom={90}
            title="Farewell - Oil - 20 X 16"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/d618879a-6b42-1d48-0793-8c7466b07287.jpg"
          />
          <GalleryItemNew
            width={300}
            title="Deliquesce - Oil - 20 X 16"
            src="https://az-art-v2.s3.amazonaws.com/IN+VITRO/6b716484-6d96-fed1-a954-eba3cd039037.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
    </GalleryGrid>
  );
};

export default InVitroGallery;
