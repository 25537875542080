import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { themeToMainColor } from "../util/theme";
import StyledLink from "./common/StyledLink";

const NavWrapper = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: ${(props) => themeToMainColor(props.theme)};
  background-color: rgba(0, 0, 0, 0);
  z-index: 100;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

interface MenuItemProps {
  active?: boolean;
}

const MenuItem = styled.h1<MenuItemProps>`
  margin-left: 10px;
  margin-right: 10px;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  transition: 0.2s ease opacity;

  &:hover {
    opacity: 1;
  }
`;

const MenuDivider = styled.h1`
  opacity: 0.5;
`;

interface NavProps {
  hide: boolean;
}

const Nav: React.FC<NavProps> = ({ hide }) => {
  const location = useLocation();
  const isActive = (link: string) =>
    location.pathname.includes(link.toLowerCase());

  return (
    <NavWrapper>
      <MenuItem active={isActive("Home")}>
        <StyledLink to="/">Home</StyledLink>
      </MenuItem>
      <MenuDivider>/</MenuDivider>
      <MenuItem active={isActive("About")}>
        <StyledLink to="/about">About</StyledLink>
      </MenuItem>
      <MenuDivider>/</MenuDivider>
      <MenuItem active={isActive("Gallery")}>
        <StyledLink to="/gallery">Gallery</StyledLink>
      </MenuItem>
      <MenuDivider>/</MenuDivider>
      <MenuItem active={isActive("Contact")}>
        <StyledLink to="/contact">Contact</StyledLink>
      </MenuItem>
      <MenuDivider>/</MenuDivider>
      <MenuItem>
        <a
          href="https://antonzhouart.square.site/"
          target="_blank"
          rel="noreferrer"
        >
          Shop
        </a>
      </MenuItem>
    </NavWrapper>
  );
};

export default Nav;
