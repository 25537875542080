import React from "react";
import styled from "styled-components";

type GalleryPaddingProps = {
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
};

const GalleryItemWrapper = styled.div<GalleryPaddingProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding-top: ${({ top }) => top || 0}px;
  padding-bottom: ${({ bottom }) => bottom || 0}px;
  padding-left: ${({ left }) => left || 0}px;
  padding-right: ${({ right }) => right || 0}px;
`;

const GalleryItemTitle = styled.span`
  font-size: 1.5em;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const getDimensions = (
  w: string | number | undefined,
  h: string | number | undefined
) => {
  if (!h && !w) {
    return {
      h: "auto",
      w: "100%",
    };
  }

  if (h && !w) {
    return {
      h: String(h) + "px",
      w: "auto",
    };
  }

  if (w && !h) {
    return {
      w: String(w) + "px",
      h: "auto",
    };
  }

  return {
    w: String(w) + "px",
    h: String(h) + "px",
  };
};

const GalleryItemImage = styled.img<GalleryPaddingProps>`
  width: ${({ width, height }) => getDimensions(width, height).w};
  max-width: ${({ width }) => width || "auto"}px;
  height: ${({ width, height }) => getDimensions(width, height).h};
`;

type GalleryItemNewProps = {
  src: string;
  height?: string | number;
  width?: string | number;
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
  title?: string;
};

const GalleryItemNew: React.FC<GalleryItemNewProps> = ({
  src,
  height,
  width,
  top,
  left,
  bottom,
  right,
  title,
}) => {
  return (
    <GalleryItemWrapper top={top} bottom={bottom} left={left} right={right}>
      <GalleryItemImage src={src} height={height} width={width} />
      {title && <GalleryItemTitle>{title}</GalleryItemTitle>}
    </GalleryItemWrapper>
  );
};

export default GalleryItemNew;
