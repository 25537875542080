import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import InVitroGallery from "./Gallery/InVitroGallery";
import GalleryIndexPage from "./Gallery/GalleryIndexPage";
import LiliesGallery from "./Gallery/LiliesGallery";
import SolitudeGallery from "./Gallery/SolitudeGallery";
import WesternGallery from "./Gallery/WesternGallery";
import StudiesGallery from "./Gallery/StudiesGallery";
import DrawingsGallery from "./Gallery/DrawingsGallery";
import EarlyWorkGallery from "./Gallery/EarlyWorkGallery";

const GalleryRouter = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route path={path} exact component={GalleryIndexPage} />
      <Route path={`${path}/in-vitro`} exact component={InVitroGallery} />
      <Route path={`${path}/lilies`} exact component={LiliesGallery} />
      <Route path={`${path}/solitude`} exact component={SolitudeGallery} />
      <Route path={`${path}/western`} exact component={WesternGallery} />
      <Route path={`${path}/studies`} exact component={StudiesGallery} />
      <Route path={`${path}/drawings`} exact component={DrawingsGallery} />
      <Route path={`${path}/early-work`} exact component={EarlyWorkGallery} />
    </>
  );
};

export default GalleryRouter;
