import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GALLERY_CATEGORY_MAP, GALLERY_CATEGORIES } from "../../constants";

const PhotowheelWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

interface PhotowheelImageProps {
  active: boolean;
  index: number;
  direction: number;
}

const PhotowheelImage = styled.img<PhotowheelImageProps>`
  position: absolute;
  right: ${(props) => props.index * 10.5}%;
  z-index: ${(props) => 50 - props.index};
  width: ${(props) => (props.active ? 70 : 70 - props.index * 8)}%;
  height: auto;
  opacity: ${(props) => (props.active ? "1" : "0.8")};
  transition: opacity 0s ease 0.1s,
    right ${(props) => (props.direction < 0 ? 0.8 : 0.4)}s ease
      ${(props) => (props.direction < 0 ? 0 : 0.2)}s,
    width ${(props) => (props.direction < 0 ? 0.4 : 0.2)}s ease
      ${(props) => (props.direction < 0 ? 0.1 : 0.4)}s,
    height ${(props) => (props.direction < 0 ? 0.4 : 0)}s ease
      ${(props) => (props.direction < 0 ? 0.1 : 0.4)}s,
    z-index 0s linear ${(props) => (props.direction < 0 ? 0.1 : 0.5)}s,
    opacity 0.2s ease
      ${(props) => (props.active ? 0 : props.direction < 0 ? 0.1 : 0.6)}s;
  top: 50%;
  transform: translateY(-50%);
`;

interface PhotowheelProps {
  initialOrder: string[];
  activeCategory: string;
}

const DEFAULT_IMG_SRC = GALLERY_CATEGORY_MAP[GALLERY_CATEGORIES[0]];

const GalleryIndexPhotowheel: React.FC<PhotowheelProps> = ({
  initialOrder,
  activeCategory,
}) => {
  const [currentOrder, setCurrentOrder] = useState<string[]>(initialOrder);
  const [prevOrder, setPrevOrder] = useState<string[]>(initialOrder);
  console.log(activeCategory, initialOrder)

  useEffect(() => {
    const active = currentOrder[0];
    if (activeCategory !== active) {
      const newActiveIdx = currentOrder.indexOf(activeCategory);
      if (newActiveIdx < 0) {
        return;
      }

      setPrevOrder(currentOrder);
      const newOrder = currentOrder.slice(1).concat(currentOrder.slice(0, 1));
      setCurrentOrder(newOrder);
    }
  }, [activeCategory, currentOrder]);

  return (
    <PhotowheelWrapper>
      {initialOrder.map((category) => {
        const currIdx = currentOrder.indexOf(category);
        const prevIdx = prevOrder.indexOf(category);
        const dir =
          currIdx - prevIdx < 0
            ? 1
            : currIdx === initialOrder.length - 1 && prevIdx === 0
            ? 1
            : -1; // 1 is clockwise, -1 is counterclockwise
        return (
          <PhotowheelImage
            key={category}
            src={GALLERY_CATEGORY_MAP[category] || DEFAULT_IMG_SRC}
            active={category === activeCategory}
            index={currIdx}
            direction={dir}
          />
        );
      })}
    </PhotowheelWrapper>
  );
};

export default GalleryIndexPhotowheel;
