import React from "react";
import {
  GalleryGrid,
  GalleryRow,
  GalleryColumn,
} from "../../components/gallery/GalleryGrid";
import GalleryItemNew from "../../components/gallery/GalleryItemNew";

const WesternGallery = () => {
  return (
    <GalleryGrid>
      <GalleryRow>
        <GalleryColumn cols={9} padding={40}>
          <GalleryItemNew
            title="In the Beginning - Oil - 16 X 20"
            src="https://az-art-v2.s3.amazonaws.com/WESTERN/45c86300-f48d-981e-2e30-e0fd2985a239.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={9} padding={40}>
          <GalleryItemNew
            title="The Path Less Traveled - Oil - 16 X 20"
            src="https://az-art-v2.s3.amazonaws.com/WESTERN/acaa3e10-8c40-4966-b632-a120b138f047.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
      <GalleryRow>
        <GalleryColumn cols={12}>
          <GalleryItemNew
            title="Southern Palate - Oil - 18 X 24"
            src="https://az-art-v2.s3.amazonaws.com/WESTERN/e22f1f0c-6265-e4bb-8958-c966c3a99b74.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
      <GalleryRow>
        <GalleryColumn cols={9} padding={40}>
          <GalleryItemNew
            title="Southern Comfort - Oil - 18 X 24"
            src="https://az-art-v2.s3.amazonaws.com/WESTERN/2e08c1dc-e55e-f60c-956d-1737e04b952e.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={9} padding={40}>
          <GalleryItemNew
            title="Taste of Texas - Oil - 18 X 24"
            src="https://az-art-v2.s3.amazonaws.com/WESTERN/4865da72-b741-74e1-df33-b5e0abda78ec.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
    </GalleryGrid>
  );
};

export default WesternGallery;
