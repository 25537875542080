import React from "react";
import {
  GalleryGrid,
  GalleryRow,
  GalleryColumn,
} from "../../components/gallery/GalleryGrid";
import GalleryItemNew from "../../components/gallery/GalleryItemNew";

const LiliesGallery = () => {
  return (
    <GalleryGrid>
      <GalleryRow>
        <GalleryColumn cols={18}>
          <GalleryItemNew
            title="Lotus Abstraction - Oil - 24 X 48"
            src="https://az-art-v2.s3.amazonaws.com/LILIES/8ab644f1-0388-ca6d-a796-1df1420bb79d.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
      <GalleryRow>
        <GalleryColumn cols={18}>
          <GalleryItemNew
            title="Water Lilies - Sunrise - Oil - 36 X 48"
            src="https://az-art-v2.s3.amazonaws.com/LILIES/65b8957a-1108-8e1b-a075-ef82e903caa6.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
      <GalleryRow>
        <GalleryColumn cols={18}>
          <GalleryItemNew
            title="Water Lilies - Dawn - Oil - 24 X 48"
            src="https://az-art-v2.s3.amazonaws.com/LILIES/c69c003e-344e-20fe-3645-0127b50be710.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
      <GalleryRow>
        <GalleryColumn cols={18}>
          <GalleryItemNew
            title="Water Lilies - Afternoon - Oil - 36 X 36"
            src="https://az-art-v2.s3.amazonaws.com/LILIES/79733e7d-703e-3f57-9758-c447e0385219.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
      <GalleryRow>
        <GalleryColumn cols={18}>
          <GalleryItemNew
            title="Water Lilies -Evening - Oil - 48 X 60"
            src="https://az-art-v2.s3.amazonaws.com/LILIES/07532cde-2820-0e2f-304e-135b401e145a.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
    </GalleryGrid>
  );
};

export default LiliesGallery;
