import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Instagram, Facebook, Youtube } from "@styled-icons/boxicons-logos";
import NewTabLink from "./common/NewTabLink";
import { themeToMainColor } from "../util/theme";

const FooterWrapper = styled.footer`
  height: 100px;
  width: 100%;
  font-family: freight-neo-pro;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: ${(props) => themeToMainColor(props.theme)};
`;

const StyledInstagram = styled(Instagram)`
  color: ${(props) => themeToMainColor(props.theme)};
  margin-left: 10px;
`;

const StyledFacebook = styled(Facebook)`
  color: ${(props) => themeToMainColor(props.theme)};
  margin-left: 5px;
`;

const StyledYoutube = styled(Youtube)`
  color: ${(props) => themeToMainColor(props.theme)};
  margin-left: 5px;
`;

const Footer: React.FC = () => {
  const location = useLocation();
  const currentYear = (new Date()).getFullYear();

  if (location.pathname === "/") {
    return null;
  }
  return (
    <FooterWrapper>
      <p>© {currentYear} Anton Zhou</p>
      <NewTabLink href="https://www.instagram.com/antonzhouart">
        <StyledInstagram size={25} />
      </NewTabLink>
      <NewTabLink href="https://www.facebook.com/antonzhouart">
        <StyledFacebook size={25} />
      </NewTabLink>
      <NewTabLink href="https://www.youtube.com/channel/UCb-pMN7PSaSBYVJZ8Uqp6MA">
        <StyledYoutube size={25} />
      </NewTabLink>
    </FooterWrapper>
  );
};

export default Footer;
