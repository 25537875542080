import React from "react";
import {
  GalleryGrid,
  GalleryRow,
  GalleryColumn,
} from "../../components/gallery/GalleryGrid";
import GalleryItemNew from "../../components/gallery/GalleryItemNew";

const EarlyWorkGallery = () => {
  return (
    <GalleryGrid>
      <GalleryRow>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Metro Blues"
            src="https://az-art-v2.s3.amazonaws.com/EARLY+WORK/35505e59-5b30-be23-00d0-f23cfe21b742.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Blue St."
            src="https://az-art-v2.s3.amazonaws.com/EARLY+WORK/ff8487c6-b793-907a-a384-0826d2a3925c.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Chief"
            src="https://az-art-v2.s3.amazonaws.com/EARLY+WORK/9e714ed3-2c7e-8b68-5abd-b40e30b229b9.jpg"
          />
        </GalleryColumn>
      </GalleryRow>

      <GalleryRow>
        <GalleryColumn cols={12} padding={40} justify="center">
          <GalleryItemNew
            title="Chasm"
            src="https://az-art-v2.s3.amazonaws.com/EARLY+WORK/4839f1da-53d6-84da-cc84-37e2f8142e0b.jpg"
          />
        </GalleryColumn>
      </GalleryRow>

      <GalleryRow>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            bottom={80}
            title="Preparations"
            src="https://az-art-v2.s3.amazonaws.com/EARLY+WORK/2c83d3dd-f481-fb77-b701-13d97b339dea.jpg"
          />
          <GalleryItemNew
            width={250}
            title="Zhou Zhuang Boat"
            src="https://az-art-v2.s3.amazonaws.com/EARLY+WORK/a335fe57-7a40-7b72-22af-178427e9ed05.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            title="Traverse My Landscape"
            src="https://az-art-v2.s3.amazonaws.com/EARLY+WORK/5d344305-cd13-9022-2192-42a9064cef33.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={6} padding={40}>
          <GalleryItemNew
            bottom={80}
            title="Crab Season"
            src="https://az-art-v2.s3.amazonaws.com/EARLY+WORK/629194ec-87e1-3de9-0432-3475f1bfda5e.jpg"
          />
          <GalleryItemNew
            width={250}
            title="Table for Two"
            src="https://az-art-v2.s3.amazonaws.com/EARLY+WORK/4d43ccb8-3623-1ae5-330c-3ecd56cfc701.jpg"
          />
        </GalleryColumn>
      </GalleryRow>

      <GalleryRow>
        <GalleryColumn cols={4} padding={40}>
          <GalleryItemNew
            title="Zhou Zhuang - Afternoon"
            src="https://az-art-v2.s3.amazonaws.com/EARLY+WORK/0c129783-b405-6712-fcda-9f54a7cf22f1.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={10} padding={40}>
          <GalleryItemNew
            title="Tuscano Impression"
            src="https://az-art-v2.s3.amazonaws.com/EARLY+WORK/bab14a9c-198e-8b8e-ace8-06059a638b1b.jpg"
          />
        </GalleryColumn>
        <GalleryColumn cols={4} padding={40}>
          <GalleryItemNew
            title="Zhou Zhuang - Morning"
            src="https://az-art-v2.s3.amazonaws.com/EARLY+WORK/618705db-e286-8b21-d181-5859a658c709.jpg"
          />
        </GalleryColumn>
      </GalleryRow>
    </GalleryGrid>
  );
};

export default EarlyWorkGallery;
