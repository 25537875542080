import React, { useState } from "react";
import styled from "styled-components";
import StyledLink from "../../components/common/StyledLink";
import PageWrapper from "../../components/common/PageWrapper";
import GalleryIndexPhotowheel from "../../components/gallery/GalleryIndexPhotowheel";
import { GALLERY_CATEGORIES } from "../../constants";
import { themeToMainColor } from "../../util/theme";

const GalleryIndexPageWrapper = styled(PageWrapper)`
  margin: 0;
`;

const GalleryCategorySelectorWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 50px;
  align-items: center;
  width: 70%;
`;

const GalleryCategoryLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

const GalleryCategory = styled.h1`
  color: ${(props) => themeToMainColor(props.theme)};
  font-size: 2.5em;
  text-align: left;
  margin: 20px 0;
  position: relative;
  opacity: 0.7;
  transition: 0.2s ease opacity;

  &::after {
    content: "";
    height: 2px;
    width: 0px;
    background-color: ${(props) => themeToMainColor(props.theme)};
    transition: 0.5s ease all;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &:hover {
    opacity: 1;

    &::after {
      width: 70%;
    }
  }
`;

const GalleryIndexPage: React.FC = () => {
  const [hovered, setHovered] = useState<string>(GALLERY_CATEGORIES[0]);
  const mappedCategories = GALLERY_CATEGORIES.map((category) => category.replace(/ /g, "-").toLowerCase());
  return (
    <GalleryIndexPageWrapper>
      <GalleryCategorySelectorWrapper>
        <GalleryIndexPhotowheel
          activeCategory={hovered}
          initialOrder={mappedCategories}
        />
        <GalleryCategoryLinks>
          {GALLERY_CATEGORIES.map((category) => {
            const slug = category.replace(/ /g, "-").toLowerCase();
            return (
              <StyledLink key={slug} to={`/gallery/${slug}`}>
                <GalleryCategory
                  onMouseOver={() => {
                    if (slug !== hovered) {
                      setHovered(slug);
                    }
                  }}
                >
                  {category}
                </GalleryCategory>
              </StyledLink>
            );
          })}
        </GalleryCategoryLinks>
      </GalleryCategorySelectorWrapper>
    </GalleryIndexPageWrapper>
  );
};

export default GalleryIndexPage;
