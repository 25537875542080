import React, { useEffect } from "react";
import styled from "styled-components";
import Button from "../components/common/Button";
import StyledLink from "../components/common/StyledLink";
import useWindowSize from "../hooks/useWindowSize";
import PageWrapper from "../components/common/PageWrapper";
import { useTheme } from "../theme/ThemeContext";
import IndexHeaderSplash from "../static/index_header.png";
import ArtistStatement from "../components/home/ArtistStatement";
import Awards from "../components/home/Awards";
import { themeToBgColor } from "../util/theme";
import FeaturedWorks from "../components/home/FeaturedWorks";

const IndexPageWrapper = styled(PageWrapper)`
  padding-top: 0;
  padding-bottom: 0;
  height: auto;
  display: block;
`;

const IndexPageLandingContainer = styled.div`
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  * {
    z-index: 10;
  }
`;

const IndexPageTitle = styled.h1`
  font-weight: normal;
  font-size: 6em;
  font-family: freight-big-pro;
  font-weight: 500;
  font-style: normal;
  color: white;
`;

const VideoAttribution = styled.span`
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    margin: 1em;
  }
`;

interface VideoProps {
  height: number;
  width: number;
}

const Video = styled.video<VideoProps>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const HeaderSplashImg = styled.img`
  max-height: 300px;
  width: 100%;
`;

const RestContentWrapper = styled.div`
  background-color: ${({ theme }) => themeToBgColor(theme)};
`;

const IndexPage: React.FC = () => {
  const sourceVideoRatio = 1920 / 1080.0;
  const { h, w } = useWindowSize();
  const scaledH = w / sourceVideoRatio;
  const scaledW = h * sourceVideoRatio;
  const themeContext = useTheme();
  useEffect(() => {
    const { mode } = themeContext.getTheme();
    themeContext.setTheme("light");

    return () => themeContext.setTheme(mode);
  }, []);

  return (
    <IndexPageWrapper>
      <IndexPageLandingContainer>
        <IndexPageTitle>Anton Zhou</IndexPageTitle>
        <ButtonWrapper>
          <StyledLink to="/gallery">
            <Button>SEE WORK</Button>
          </StyledLink>
          <StyledLink to="/about">
            <Button>ABOUT ME</Button>
          </StyledLink>
        </ButtonWrapper>
        <Video
          loop
          autoPlay
          muted
          height={Math.max(h, scaledH)}
          width={Math.max(w, scaledW)}
        >
          <source
            src="https://az-art-uploads.s3.amazonaws.com/static/index_video.mp4"
            type="video/mp4"
          />
        </Video>
        <VideoAttribution>Cinematography by Kevin Chiu</VideoAttribution>
      </IndexPageLandingContainer>
      <RestContentWrapper>
        <FeaturedWorks />
        <HeaderSplashImg src={IndexHeaderSplash} />
        <ArtistStatement />
        <Awards />
      </RestContentWrapper>
      <div style={{ display: "none" }} dangerouslySetInnerHTML={{__html: `
        <a href="javascript:;" onclick="ml_webform_4166083('show')">Click here to show popup</a>
      `}} />
    </IndexPageWrapper>
  );
};

export default IndexPage;
